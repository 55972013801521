import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useWindowHeight } from '@react-hook/window-size';
import AssetDrawerInformation from './AssetDrawerInformation';
import AssetDrawerTabs from './AssetDrawerTabs';
import IncidentsTable from '../DrawerTabs/IncidentsTable';
import { useSelector } from 'react-redux';
import DiscoveredAssetDrawerInformation from './DiscoveredAssetDrawerInformation';
import LanesTable from '../DrawerTabs/LanesTable';
import MaterialTable from '../DrawerTabs/MaterialTable';
import { isDiscovered } from 'discover/modules/graphUtilities';
import RisksTab from '../DrawerTabs/RisksTab';
import OutboundFlows from '../DrawerTabs/OutboundFlows';
import ForecastTab from '../DrawerTabs/ForecastTab';
import FeedbackTable from '../DrawerTabs/FeedbackTable';

const AssetDiscoverDrawer = styled(({ className, closeAssetDrawer }) => {
  const [activeRiskModel, setActiveRiskModel] = useState(0);
  const { discover_assets } = useSelector(store => store.assets);
  const selectedNode = useSelector(store => store.networkGraph.selectedNode);
  const orgMaterialPref = useSelector(store => store.accountOrg.accountOrg?.prefs?.materials?.enabled || false);
  const windowHeight = useWindowHeight() - 80;
  const [drawerHeight, setDrawerHeight] = useState(400);
  const [assetTabs, setAssetTabs] = useState([]);
  const isUsingFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
  const positionRef = useRef(null);

  useEffect(() => {
    if (isUsingFirefox) window.addEventListener('dragover', handleDragOver);
    return () => {
      if (isUsingFirefox) window.removeEventListener('dragover', handleDragOver);
    };
  }, [isUsingFirefox]);

  const selectedAsset = useMemo(
    () =>
      discover_assets?.find(f => {
        return f.asset.name?.toLowerCase() === selectedNode?.name?.toLowerCase();
      }),
    [discover_assets, selectedNode],
  );

  useEffect(() => {
    let incidentCount = 0;
    if (selectedAsset && Array.isArray(selectedAsset.risk?.threats) && selectedAsset.risk?.threats.length > 0) {
      incidentCount = selectedAsset.risk?.threats.length;
    }
    const tempAssetTabs = [
      {
        title: 'Incidents' + (incidentCount > 0 ? ' (' + incidentCount + ')' : ''),
        tabKey: `incidents${Math.random()}`,
        content: <IncidentsTable assetId={selectedAsset?.id} />,
      },
      !isDiscovered(selectedNode) &&
        selectedAsset?.id && {
          title: 'Forecast',
          tabKey: `forecast${Math.random()}`,
          content: <ForecastTab assetId={selectedAsset?.id} risk={selectedAsset.risk} />,
        },
      selectedAsset?.id &&
        selectedAsset?.proactive_scores?.length && {
          title: 'Risk',
          tabKey: `risk${Math.random()}`,
          content: (
            <RisksTab
              assetId={selectedAsset?.id}
              proactiveScores={selectedAsset.proactive_scores}
              activeRiskModel={activeRiskModel}
              setActiveRiskModel={setActiveRiskModel}
              isEkg={selectedAsset?.system_type === '3'}
            />
          ),
        },
      !isDiscovered(selectedNode) &&
        selectedAsset?.id && {
          title: 'Lanes',
          tabKey: `lanes${Math.random()}`,
          content: <LanesTable assetId={selectedAsset?.id} />,
        },
      !isDiscovered(selectedNode) &&
        selectedAsset?.id && {
          title: 'Feedback',
          tabKey: `feedback${Math.random()}`,
          content: <FeedbackTable assetId={selectedAsset?.id} />,
        },
      !isDiscovered(selectedNode) &&
        selectedAsset?.id &&
        orgMaterialPref && {
          title: 'Material',
          tabKey: `material${Math.random()}`,
          content: <MaterialTable assetId={selectedAsset?.id} />,
        },
      selectedAsset?.system_type === '3' && {
        title: 'Outbound Flows',
        tabKey: `outbound_flows${Math.random()}`,
        content: <OutboundFlows node={selectedNode} />,
      },
    ].filter(e => e);
    setAssetTabs(tempAssetTabs);
  }, [discover_assets, selectedNode, selectedAsset, orgMaterialPref, activeRiskModel]);

  const draggingDrawer = drawerPosition => {
    if (positionRef.current && drawerPosition.timeStamp - positionRef.current.timeStamp < 100) {
      drawerPosition.clientY = positionRef.current.clientY;
    }

    if (drawerPosition.clientY > 0 && drawerHeight !== windowHeight - drawerPosition.clientY) {
      const drwHeight = windowHeight - (drawerPosition.clientY < 210 ? 210 : drawerPosition.clientY);
      if (drwHeight > 20) {
        setDrawerHeight(drwHeight);
      }
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
    positionRef.current = e;
  };

  return (
    <div className={`${className}`} style={{ height: drawerHeight }}>
      <div className={'resizer'} draggable="true" onDrag={draggingDrawer} onDragEnd={e => (positionRef.current = null)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {selectedAsset ? (
        <>
          <AssetDrawerInformation
            className={'asset-drawer-info'}
            asset={selectedAsset}
            closeDrawer={closeAssetDrawer}
            activeRiskModel={activeRiskModel}
            selectedNode={selectedNode}
          />
          <AssetDrawerTabs className={'asset-drawer-tabs'} assetTabs={assetTabs} />
        </>
      ) : (
        selectedNode && (
          <>
            <DiscoveredAssetDrawerInformation
              className={'asset-drawer-info'}
              asset={selectedNode}
              closeDrawer={closeAssetDrawer}
              isEkg={isDiscovered(selectedNode)}
            />
            <AssetDrawerTabs className={'asset-drawer-tabs'} assetTabs={assetTabs} />
          </>
        )
      )}
    </div>
  );
})`
  align-items: stretch;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-name: discoverAssetDrawerSlideUp;
  animation-timing-function: ease-out;
  background: transparent;
  display: flex;
  flex-grow: 1;
  height: auto;
  position: absolute;
  width: 100%;

  .resizer {
    background-color: #000;
    height: 20px;
    position: absolute;
    resize: vertical;
    top: 0px;
    width: 100%;
    z-index: 40;

    :active {
      animation: none;
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
      opacity: 0;
    }

    :hover {
      cursor: ns-resize;
    }

    span {
      display: block;
      margin: 3px auto;
      height: 2px;
      width: 30px;
      background: #212121;
      border-radius: 9px;
    }
  }

  .asset-drawer-info {
    margin-top: -150px;
    min-width: 560px;
    user-select: none;
    width: 30%;
    z-index: 45;
  }

  .asset-drawer-tabs {
    border-top: 20px solid #000000;
    user-select: none;
    width: 70%;
  }

  @keyframes discoverAssetDrawerSlideUp {
    0% {
      bottom: -600px;
    }
    85% {
      bottom: 0px;
    }
    100% {
      bottom: 0px;
    }
  }
`;

export default AssetDiscoverDrawer;
